import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeaderSimple from "../components/HeaderSimple";

import "../utils/general.scss";
import "../utils/page-careers.scss";

const InCosaCrediamo = ({ location, pageContext }) => {
    return (
        <Layout pageContext={pageContext}>
            <SEO title={pageContext.localeResources['menu.careers']} description="Scopri le posizioni attualmente aperte e candidati">
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
            </SEO>
            
            <HeaderSimple title={`${pageContext.localeResources['careers.boxes.third.title']}`} subtitle={`${pageContext.localeResources['careers.mainDescription.title']}`} />
            
            <section className="section-text">
                <div className="container section">
                    <div className="text">
                        <h5 className="subtitle" data-aos="fade-right" data-aos-offset="200">{pageContext.localeResources['benefits.first.title']}</h5>
                        <p className="ie" data-aos="fade-right" data-aos-offset="200" dangerouslySetInnerHTML={{__html: pageContext.localeResources['benefits.first.text']}}></p>
                    </div>
                    <div className="text">
                        <h5 className="subtitle" data-aos="fade-left" data-aos-offset="200">{pageContext.localeResources['benefits.second.title']}</h5>
                        <p className="ie" data-aos="fade-left" data-aos-offset="200" dangerouslySetInnerHTML={{__html: pageContext.localeResources['benefits.second.text']}}></p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default InCosaCrediamo
