import React from "react";
import Title from "../Title";

import "./style.scss";

const HeaderSimple = ({title, subtitle}) => (
    <section className="header-simple">
        <div className="container section">
            {subtitle && <span className="subtitle gray ie">{subtitle}</span>}
            {title && <Title title={title} />}
        </div>
    </section>
)

export default HeaderSimple
